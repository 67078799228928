<script setup>
import { defineProps, defineEmits, onMounted, onUnmounted } from 'vue';
import { Editor as CoreEditor, getRichTextExtensions, getStarterExtensions, SuperToolbar } from '@harbour-enterprises/superdoc/super-editor';

const emit = defineEmits(['input']);

const props = defineProps({
    id: {
        type: String,
        required: false,
    },
    defaultValue: {
        type: String,
    },
    isSuperdoc: {
        type: Boolean,
        default: false,
    },
})

let superdocInstance = null;

onMounted(() => {
    if (props.isSuperdoc) initSuperdoc();
})

const handleInput = (text) => {
    if (props.isSuperdoc) {
        emit('input', superdocInstance.getHTML());
    } else {
        if (typeof text !== 'string') return;
        emit('input', text);
    }
}

let toolbar = null;

const addToolbar = () => {
    const config = {
        element: toolbarId,
        toolbarGroups: ['center'],
        mode: 'text',
        toolbarButtonsExclude: [
            'zoom',
            'separator',
            'fontFamily',
            'link',
            'image',
            'formatPainter',
            'clearFormatting',
            'copyFormat',
            'table',
            'tableActions',
        ],
    }

    toolbar = new SuperToolbar(config);
    superdocInstance.setToolbar(toolbar);
}

const htmlBufferId = `superdoc-html-buffer_${props.id}`;
const containerId = `superdoc-container_${props.id}`;
const toolbarId = `toolbar_${props.id}`;


const initSuperdoc = async () => {
    if (!props.id) throw new Error('MiniEditor: id is missing or invalid');

    console.log('MiniEditor: initSuperdoc');

    superdocInstance = new CoreEditor({
        mode: "text",
        content: document.getElementById(htmlBufferId),
        element: document.getElementById(containerId),
        extensions: [...getRichTextExtensions(), ...getStarterExtensions()],
    });

    addToolbar();
}
</script>

<template>
    <div class="superdoc-elements" v-if="isSuperdoc">
        <div :id="toolbarId">toolbar</div>
        <div :id="htmlBufferId" style="display:none;" v-html="props.defaultValue"></div>
        <div :id="containerId" class="superdoc-container sd-theme" @keyup="handleInput"></div>
    </div>
    <component
        :is="isSuperdoc ? 'div' : 'vue-component-ckeditor'"
        v-else :ondocumentupdate="(_, value) => handleInput(value)"
        :item="{itemdefaultvalue: props.defaultValue}"
        :disabled="false" style="width: 100%; height: 250px">
    </component>
</template>

<style scoped>
.superdoc-container {
    border: solid 1px #e2e0e0;
    background-color: white;
    padding: 7px;
    border-radius: 5px;
    transition: all 250ms ease;
}

.superdoc-container :deep(.ProseMirror){
    min-height: 100px;
    padding: 1em;
    padding-left: 2em;
    outline: none;
}
.superdoc-container:hover {
  border: 1px solid #0160cc86;
  box-shadow:0 0 5px hsla( 0,0%,0%,.1);
}
.superdoc-container:focus-within {
  border: 1px solid #015fcc;
  box-shadow:0 0 5px hsla( 0,0%,0%,.3 );
}
</style>
